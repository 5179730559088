import React from 'react'
import BestProducts from '../components/Best'

function Best() {
  return (
    <div>
   <BestProducts/> 
    </div>
  )
}

export default Best;