import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/shopCategories.css';
import face from '../assets/images/banner2.webp';
import eye from '../assets/images/makeup2.jpg';
import lips from '../assets/images/lip-makeup.jpg';
import bodycare from '../assets/images/brands5.jpg';
import fragrance from '../assets/images/hera.webp';
import skincare from '../assets/images/oliveyoung2.jpg';

const ShopCategories = () => {
  const navigate = useNavigate();

  const categories = [
    {
      title: "SKINCARE",
      image: skincare,
      link: "/category/skincare"
    },
    {
      title: "FACE",
      image: face,
      link: "/category/face"
    },
    {
      title: "EYE",
      image: eye,
      link: "/category/eye"
    },
    {
      title: "LIPS",
      image: lips,
      link: "/category/lips"
    },
    {
      title: "BODY CARE",
      image: bodycare,
      link: "/category/body-care"
    },
    {
      title: "FRAGRANCE",
      image: fragrance,
      link: "/category/fragrance"
    }
  ];

  return (
    <div className="shop-categories">
      <h3 className="shopbycategory-title">Shop by Category</h3>
      <div className="shop-categories__grid">
        {categories.map((category, index) => (
          <div 
            key={index}
            className="shop-categories__item"
            onClick={() => navigate(category.link)}
          >
            <div className="shop-categories__image-wrapper">
              <img 
                src={category.image} 
                alt={category.title} 
                className="shop-categories__image"
              />
            </div>
            <h3 className="shop-categories__title">{category.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopCategories;
