import React from 'react';
import FilterSection from '../components/Filter';
import ProductList from '../components/ProductList';
import '../styles/category.css'; 

function Category() {
  return (
    <div className="category-container">
      <div className='category-content'>
        <FilterSection  />
        <ProductList />
      </div>
    </div>
  );
}

export default Category;
