import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProductById,
  fetchProductSizes,
  fetchProductOptions,
} from "../actions/ProductActions";
import Product from "../components/Product";
import Loader from "../components/Loader";
const ProductView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    selectedProduct,
    loading,
    error,
    sizes,
    options,
    sizesLoading,
    optionsLoading,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProductById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(fetchProductSizes(id));
      dispatch(fetchProductOptions(id));
    }
  }, [dispatch, selectedProduct, id]);

  if (loading || sizesLoading || optionsLoading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {selectedProduct ? (
        <Product product={selectedProduct} sizes={sizes} options={options} />
      ) : (
        <div>Product not found</div>
      )}
    </div>
  );
};

export default ProductView;
