import axios from 'axios';
import { API_URL } from '../variables';

export const FETCH_SITE_REQUEST = 'FETCH_SITE_REQUEST';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAILURE = 'FETCH_SITE_FAILURE';

export const fetchSite = () => async (dispatch) => {
  dispatch({ type: FETCH_SITE_REQUEST });

  try {
    const response = await axios.get(`${API_URL}/api/users/siteSettings`);
    dispatch({
      type: FETCH_SITE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_SITE_FAILURE,
      payload: error.message
    });
  }
}; 