import React from 'react';
import '../styles/adSection.css';

const AdSection = () => {
  return (
    <section className="ad-section">
      <div className="ad-content">
        <h2>K-Beauty Rewards</h2>
        <div className="benefits-list">
          <div className="benefit-item">
            <span className="benefit-icon">🎁</span>
            <p className="benefit-text">Free Gift</p>
          </div>
          <div className="benefit-divider"></div>
          <div className="benefit-item">
            <span className="benefit-icon">🚚</span>
            <p className="benefit-text">Free Shipping</p>
          </div>
          <div className="benefit-divider"></div>
          <div className="benefit-item">
            <span className="benefit-icon">💝</span>
            <p className="benefit-text">15% OFF Coupon</p>
          </div>
        </div>
        <div className="promo-sticker">
          <div className="sticker-content">
            <span className="sticker-label">NEW MEMBER OFFER</span>
            <span className="sticker-value">Get 15% Off</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdSection; 