import axios from 'axios';
import { API_URL } from '../variables';

export const SUBMIT_REVIEW_REQUEST = 'SUBMIT_REVIEW_REQUEST';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILURE = 'SUBMIT_REVIEW_FAILURE';
export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';
export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';

export const submitReview = (reviewData, productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBMIT_REVIEW_REQUEST });
    const { token, user } = getState().auth;

    if (!user || !token) {
      throw new Error('User must be authenticated to submit a review');
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${token}`,
      }
    };

    const formData = new FormData();
    formData.append('product_id', productId);
    formData.append('rating', reviewData.rating);
    formData.append('review', reviewData.comment);

    console.log('Sending review data:', {
      product_id: productId,
      rating: reviewData.rating,
      review: reviewData.comment
    });

    const { data } = await axios.post(
      `${API_URL}/api/products/reviews/add`, 
      formData, 
      config
    );
    
    console.log('Server response:', data);
    dispatch({ type: SUBMIT_REVIEW_SUCCESS, payload: data });
    return data;
  } catch (error) {
    console.error('Review submission error:', error);
    dispatch({
      type: SUBMIT_REVIEW_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
    throw error;
  }
};

export const fetchReviews = (productId) => async (dispatch) => {
  dispatch({ type: FETCH_REVIEWS_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/reviews/${productId}`);
    dispatch({ type: FETCH_REVIEWS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_REVIEWS_FAILURE, payload: error.message });
  }
}

export const deleteReview = (reviewId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });
    const { token } = getState().auth;

    if (!token) {
      throw new Error('User must be authenticated to delete a review');
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${token}`,
      }
    };

    const formData = new FormData();
    formData.append('review_id', reviewId);

    const { data } = await axios.post(`${API_URL}/api/products/reviews/delete`, formData, config);
    
    dispatch({ type: DELETE_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};