const initialState = {
  loading: false,
  results: [],
  error: null,
};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WISHLIST_FETCH_REQUEST':
      return { ...state, loading: true };
    case 'WISHLIST_FETCH_SUCCESS':
      return { loading: false, results: action.payload.results, error: null };
    case 'WISHLIST_FETCH_FAIL':
      return { loading: false, results: [], error: action.payload };
    case 'WISHLIST_DELETE_SUCCESS':
      return {
        ...state,
        results: state.results.filter(item => item.wishlist_id !== action.payload),
      };
    case 'WISHLIST_DELETE_FAIL':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default wishlistReducer;
  