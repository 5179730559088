import React, { useEffect } from 'react';
import '../styles/cart.css';
import CartItem from '../components/CartItem';
import OrderSummary from '../components/Ordersummary';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../actions/cartActions';
import { useNavigate } from 'react-router-dom';
import { useImage } from "../contexts/ImageContext"; 

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { items: cartItems = [] } = useSelector((state) => state.cart); 
  const { getImageUrl } = useImage();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/signin');
    } else {
      dispatch(fetchCart(getImageUrl));
    }
  }, [dispatch, isAuthenticated, navigate, getImageUrl]);

  const cartTotal = cartItems.reduce(
    (total, item) => total + (parseFloat(item.product.price) || 0) * (item.quantity || 1),
    0
  );

  return (
    <div className="cart-container">
      <div className="cart-items-section">
        <h2 className="cart-title">Shopping Cart</h2>
        {cartItems.length === 0 ? (
          <p className="cart-empty">Your cart is empty.</p>
        ) : (
          <div className="cart-items-list">
            {cartItems.map((item) => (
              <CartItem
                key={item.cart_item_id}
                cartItemId={item.cart_item_id}
                product={{
                  productId: item.product.product_id,
                  name: item.product.name,
                  description: <div dangerouslySetInnerHTML={{ __html: item.product.description }} />,
                  price: item.product.price,
                  quantity: item.quantity,
                  imageUrl: item.product.imageUrl || 'default-image-url',
                }}
              />
            ))}
          </div>
        )} 
      </div>
      <div className="cart-sidebar">
        <div className="promos-section">
          <h4>Promos & Offers</h4>
          <input type="text" placeholder="Enter promo code" className="promo-input" />
          <button className="apply-promo-button">Apply</button>
        </div>
        <OrderSummary cartTotal={cartTotal} />
      </div>
    </div>
  );
};

export default Cart;
