import React, { useState } from 'react';
import '../styles/contactus.css';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    inquiry: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log(formData);
  };

  return (
    <div className="contact-us-container">
      <div className="contact-info green-bg">
        <h2>Contact Information</h2>
        <p>Phone: +250727219029/+250793761875</p>
        <p>Email: help@kmartrwanda.com</p>
        <p>Address: Kigali-Rwanda, Kisimenti, 1stFloor Ikaze House</p>
        <h3>Follow Us</h3>
        <ul className="social-links">
          <li>
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <FaFacebook /> k-martrwanda1
            </a>
          </li>
          <li>
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <FaTwitter /> k-martrwanda1
            </a>
          </li>
          <li>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <FaInstagram /> k-martrwanda1
            </a>
          </li>
        </ul>
      </div>

      <div className="contact-form">
        <h2>Get in Touch with us!</h2>
        <p>Any question or remarks? Want to order? Just write us a message and we'll get back to you shortly!</p>
        <form onSubmit={handleSubmit}>
          <div className="contact-form-group">
            <label htmlFor="name">Your Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="contact-form-group">
            <label htmlFor="email">Your Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="contact-form-group">
            <label htmlFor="inquiry">Your Inquiry:</label>
            <textarea
              id="inquiry"
              name="inquiry"
              value={formData.inquiry}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button type="submit" className="contact-submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
