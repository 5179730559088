import React, { useEffect } from 'react';  
import { useDispatch, useSelector } from 'react-redux'; 
import { useParams, useNavigate } from 'react-router-dom'; 
import { FaChevronLeft } from 'react-icons/fa'; 
import { getOrdersByUserId } from '../actions/ordersActions';  
import '../styles/orderdetails.css'; 
import AccountLayout from '../components/AccountLayout';

const OrderDetailsPage = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { orders = [], loading, error } = useSelector(state => state.orders);
    
    const order = orders.find(o => o.order_id === orderId); 

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin');
        } else if (user?.user_id) {
            dispatch(getOrdersByUserId(user.user_id));
        }
    }, [dispatch, isAuthenticated, navigate, user]);

    useEffect(() => {
        console.log('Order:', order);
    }, [order]);

    if (loading) {
        return <p className="order-details-loading-message">Loading order details...</p>;
    }

    if (error) {
        return <p className="order-details-error-message">{error}</p>;
    }

    if (!order) {
        return <p className="order-details-no-order-message">Order not found.</p>;
    }

    return (
        <div className="myaccount-container">
            <AccountLayout />
            <div className="order-details-container">
                <div className="order-details-header">
                    <button onClick={() => navigate('/orders')} className="order-details-back-button">
                        <FaChevronLeft/> Back to Orders
                    </button>
                    <div className="order-details-header-content">
                        <div className="order-details-header-info">
                            <h2 className="order-details-title">Order #{order.order_id}</h2>
                            <p className="order-details-date">
                                Placed on {new Date(order.created_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </p>
                            <div className="order-status-badges">
                                <div className="status-badge">
                                    <span className="status-label">Order Status</span>
                                    <span className={`order-details-status-text ${order.status.toLowerCase()}`}>
                                        {order.status}
                                    </span>
                                </div>
                                <div className="status-badge">
                                    <span className="status-label">Payment Status</span>
                                    <span className={`order-details-status-text ${order.payment_status.toLowerCase()}`}>
                                        {order.payment_status}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="payment-instructions-card">
                    <div className="payment-instructions-header">
                        <h3>Payment Instructions</h3>
                        {order.payment_status.toLowerCase() === 'unpaid' && (
                            <span className="payment-pending-badge">Payment Required</span>
                        )}
                    </div>
                    <div className="payment-instructions-content">
                        <div className="momo-code-section">
                            <span className="momo-label">MTN Mobile Money Code:</span>
                            <span className="momo-code">*182*8*1*052158#</span>
                            <span className="merchant-name">SONG & STARK COOPERATION</span>
                        </div>
                        <div className="payment-info-footer">
                            <p className="payment-note">Please complete the payment to process your order</p>
                            <button onClick={() => navigate('/contactus')} className="contact-support-btn">
                                Need Help? Contact Support
                            </button>
                        </div>
                    </div>
                </div>

                <div className="order-content-grid">
                    <div className="order-details-summary">
                        <h3>Order Summary</h3>
                        <div className="order-details-summary-row">
                            <span>Product Total:</span>
                            <span>{Number(order.total_price).toFixed(2)} RWF</span>
                        </div>
                        <div className="order-details-summary-row">
                            <span>Shipping Cost:</span>
                            <span>{order.shipping_cost || 'FREE'}</span>
                        </div>
                        <div className="order-details-summary-row total">
                            <span>Total:</span>
                            <span>{Number(order.total_price).toFixed(2)} RWF</span>
                        </div>
                    </div>

                    <div className="order-details-items-ordered">
                        <h3>Items Ordered</h3>
                        {Array.isArray(order.order_items) && order.order_items.length > 0 ? (  
                            order.order_items.map(item => (
                                <div key={item.order_item_id} className="order-details-ordered-item">
                                    <div className="order-details-item-details">
                                        <p className="order-details-item-name">{item.product.name}</p>
                                        <p className="order-details-item-quantity">Quantity: {item.quantity}</p>
                                        <p className="order-details-item-price">Price: {Number(item.price_at_purchase).toFixed(2)} RWF</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No items found for this order.</p>
                        )}
                    </div>
                </div>

                <div className="order-details-courier-info">
                    <h3>Courier Information</h3>
                    <p>Address: {order.address || 'Not Provided'}</p>
                    <p>Courier Name: {order.courier_name || 'Not Provided'}</p>
                    <p>Tracking Number: {order.tracking_number || 'N/A'}</p>
                    {order.tracking_url && (
                        <p>
                            <a href={order.tracking_url} target="_blank" rel="noopener noreferrer">
                                Track your shipment
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
export default OrderDetailsPage;
