import React, { useState } from 'react';
import '../styles/product.css';
import { FaChevronDown } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { addToCart } from '../actions/cartActions';
import { addToWishlist, deleteFromWishlist } from '../actions/wishlistActions';
import CartModal from './CartModal';
import { useImage } from "../contexts/ImageContext";
import ReviewPage from './Review';

const Product = ({ product, sizes, options }) => {
  const [activeSection, setActiveSection] = useState(null);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addedItem, setAddedItem] = useState(null);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const { getImageUrl } = useImage();
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(product.image);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const handleWishlistToggle = (e) => {
    e.stopPropagation();
    if (isInWishlist) {
      dispatch(deleteFromWishlist(product.product_id));
    } else {
      dispatch(addToWishlist(product.product_id));
    }
    setIsInWishlist(!isInWishlist);
  };

  const {
    name,
    base_price,
    discounted_price,
    description,
    description_images,
    features = [],
    image,
    other_images = [],
    product_info = [],
    specific_info = [],
    tags = [],
    reviews = [],
    product_rating = 0,
  } = product;

  const discount = base_price !== discounted_price ? Math.round((1 - (discounted_price / base_price)) * 100) : null;

  const openCartModal = (item) => {
    setAddedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const increaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
  };

  const handleAddToCart = () => {
    const productToAdd = {
      product_id: product.product_id,
      size_id: selectedSize,
      option_id: selectedOption,
      quantity: quantity
    };

    console.log('Adding product to cart:', productToAdd);

    dispatch(addToCart(productToAdd));
    setIsAddedToCart(true);
    
    const cartItem = {
      product_image: getImageUrl(product.image),
      product_name: product.name,
      quantity: quantity,
      price: product.discounted_price * quantity
    };
    
    openCartModal(cartItem);
  };

  return (
    <div className="product-view-container">
      <div className="product-view">
        <div className="product-details-wrapper">
          <div className="product-image-section">
            <div className="product-image-container">
              <div className="thumbnail-images">
                <div 
                  className={`thumbnail-item ${image === selectedImage ? 'active' : ''}`}
                  onClick={() => setSelectedImage(image)}
                >
                  <img src={getImageUrl(image)} alt={name} />
                </div>
                {other_images && other_images.map((img, index) => (
                  <div 
                    key={index}
                    className={`thumbnail-item ${img === selectedImage ? 'active' : ''}`}
                    onClick={() => setSelectedImage(img)}
                  >
                    <img 
                      src={getImageUrl(img)} 
                      alt={`${name} - ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
              <div className="main-image">
                <img src={getImageUrl(selectedImage)} alt={name} className="product-image" />
              </div>
            </div>
          </div>

          <div className="product-details-section">
            <div className="product-tags">
              {tags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>

            <h1 className="product-title">{name}</h1>

            <div className="product-rating">
              <span className="stars">★★★★★</span>
              <span className="reviews">({product_rating} Reviews)</span>
            </div>

            <div className="product-price-section">
              <span className="discounted-price">{discounted_price}RWF</span>
              {discount && (
                <>
                  <span className="original-price">{base_price}RWF</span>
                  <span className="discount">{discount}% Off</span>
                </>
              )}
            </div>

            <p className="delivery-info">We deliver to RWANDA</p>
            <p className="shipping-info">Free shipping available from 60,000 RWF</p>

            <div className="product-options">
              <label htmlFor="product-size">Select Size</label>
              <select
                id="product-size"
                className="product-size-dropdown"
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                <option value="">Select a size</option>
                {sizes.map((size) => (
                  <option key={size.id} value={size.id}>
                    {size.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="product-options">
              <label htmlFor="product-option">Select Option</label>
              <select
                id="product-option"
                className="product-option-dropdown"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="">Select an option</option>
                {product.options && product.options.map((option) => (
                  <option 
                    key={option.option_id} 
                    value={option.option_id}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="product-quantity">
              <button className="quantity-btn" onClick={decreaseQuantity}>-</button>
              <input 
                type="number" 
                value={quantity} 
                className="quantity-input" 
                readOnly 
              />
              <button className="quantity-btn" onClick={increaseQuantity}>+</button>
            </div>

            <div className="product-actions">
              <button className="add-to-cart-btn" onClick={handleAddToCart}>
                {isAddedToCart ? 'Order Added Successfully' : 'Add to Cart'}
              </button>
              <div className="wishlist-icon-container">
                {isInWishlist ? (
                  <AiFillHeart className="wishlist-icon" onClick={handleWishlistToggle} />
                ) : (
                  <AiOutlineHeart className="wishlist-icon" onClick={handleWishlistToggle} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-info-section">
        <div className="faq-item" onClick={() => toggleSection('info')}>
          <h2>Product Info</h2>
          <span className={`chevron ${activeSection === 'info' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'info' && (
          <div className="faq-content">
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {specific_info.length > 0 && (
              <ul>
                {specific_info.map((info, index) => (
                  <li key={index}><strong>{info.label}:</strong> {info.value}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('love')}>
          <h2>Why We Love It</h2>
          <span className={`chevron ${activeSection === 'love' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'love' && (
          <div className="faq-content">
            {product_info.length > 0 ? (
              <div dangerouslySetInnerHTML={{ __html: product_info.map(info => info.value).join('<br/>') }} />
            ) : (
              <p>No additional information provided.</p>
            )}
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('features')}>
          <h2>Features</h2>
          <span className={`chevron ${activeSection === 'features' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'features' && (
          <div className="faq-content">
            <ul>
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('howToUse')}>
          <h2>How to Use</h2>
          <span className={`chevron ${activeSection === 'howToUse' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'howToUse' && (
          <div className="faq-content">
            <p>Instructions on how to use the product.</p>
            {description_images && description_images.length > 0 && (
              <div className="description-images">
                {description_images.map((imageUrl, index) => (
                  <img 
                    key={index}
                    src={getImageUrl(imageUrl)}
                    alt={`Usage instruction ${index + 1}`}
                    className="description-image"
                  />
                ))}
              </div>
            )}
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('reviews')}>
          <h2>Reviews ({reviews.length})</h2>
          <span className={`chevron ${activeSection === 'reviews' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'reviews' && (
          <div className="faq-content">
            <ReviewPage reviews={reviews} productRating={product_rating} />
          </div>
        )}
      </div>

      <CartModal isOpen={isModalOpen} closeModal={closeModal} cartItem={addedItem} />
    </div>
  );
};

export default Product;
