import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { submitReview } from '../actions/reviewActions';
import '../styles/review.css';

const ReviewModal = ({ onClose, productId }) => {
    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submit clicked', { rating, review, productId }); 
        if (!productId) {
            setError('Product ID is missing');
            return;
        }
        setIsSubmitting(true);
        setError(null);
        
        try {
            console.log('Dispatching submitReview action'); 
            const result = await dispatch(submitReview({ 
                rating, 
                comment: review,
                product_id: productId  
            }, productId));
            console.log('Review submission result:', result); 
            onClose();
        } catch (err) {
            console.error('Review submission error:', err);
            setError(err.message || 'Failed to submit review');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderStars = () => {
        return [1, 2, 3, 4, 5].map((star) => (
            <button
                key={star}
                type="button"
                className={`stars-container button ${star <= rating ? 'filled' : ''}`}
                onClick={() => setRating(star)}
            >
                ★
            </button>
        ));
    };

    return (
        <div className="review-modal-overlay">
            <div className="review-modal">
                <button 
                    className="close-button"
                    onClick={onClose}
                    disabled={isSubmitting}
                >
                    ×
                </button>
                <h2>Write a Review</h2>
                
                {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="rating-container">
                        <label>Your Rating:</label>
                        <div className="stars-container">
                            {renderStars()}
                        </div>
                    </div>
                    <div className="review-input-container">
                        <label>Your Review:</label>
                        <textarea
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            disabled={isSubmitting}
                            required
                            placeholder="Write your review here..."
                            rows="4"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="submit-button"
                        disabled={isSubmitting || !rating || !review.trim()}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Review'}
                    </button>
                </form>
            </div>
        </div>
    );
};

const ReviewPage = () => {
    const product = useSelector((state) => state.products.selectedProduct);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id } = useParams(); 

    if (!product) {
        return <div>Loading...</div>;
    }

    const reviews = product.reviews || [];
    const productId = product.id || id; 


    return (
        <div className="reviewPage">
            <div className="reviewPage__content">
                <div className="reviewPage__left">
                    <div className="reviewPage__summary">
                        <h1 className="reviewPage__summaryRating">
                            {product.product_rating?.toFixed(1) || "0.0"}
                        </h1>
                        <p className="reviewPage__summaryText">{reviews.length} reviews</p>
                        <button 
                            className="reviewPage__writeReviewBtn"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Write a Review
                        </button>
                    </div>
                    <div className="reviewPage__ratingDistribution">
                        {[5, 4, 3, 2, 1].map(star => {
                            const count = reviews.filter(review => review.rating === star).length;
                            const percentage = (count / reviews.length) * 100 || 0;
                            
                            return (
                                <div key={star} className="ratingDistribution__bar">
                                    <span>{star} Stars</span>
                                    <div className="ratingDistribution__barFillContainer">
                                        <div
                                            className="ratingDistribution__barFill"
                                            style={{ width: `${percentage}%` }}
                                        ></div>
                                    </div>
                                    <span>{count} ({percentage.toFixed(0)}%)</span>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="reviewPage__right">
                    <div className="reviewPage__reviewsList">
                        {reviews.map((review) => (
                            <div key={review.review_id} className="reviewCard">
                                <div className="reviewCard__header">
                                    <div className="reviewCard__userInfo">
                                        <div>
                                            <div className="reviewCard__rating">
                                                {[...Array(5)].map((_, index) => (
                                                    <span 
                                                        key={index}
                                                        className={index < review.rating ? 'star filled' : 'star'}
                                                    >
                                                        ★
                                                    </span>
                                                ))}
                                            </div>
                                            <p className="reviewCard__meta">
                                                By {review.user.name} on {new Date(review.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="reviewCard__content">{review.review}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
            {isModalOpen && (
                <ReviewModal 
                    onClose={() => setIsModalOpen(false)}
                    productId={productId}
                />
            )}
        </div>
    );
};

export default ReviewPage;
