// ContactInfo.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../styles/checkout.css';

const ContactInfo = ({ data, setData }) => {
  const { user } = useSelector(state => state.auth);
  const [form, setForm] = useState(data || {});

  useEffect(() => {
    if (user?.phone && (!data?.primaryPhone || data.primaryPhone !== user.phone)) {
      const updatedForm = {
        primaryPhone: user.phone,
        secondaryPhone: data?.secondaryPhone || ''
      };
      setForm(updatedForm);
      setData(updatedForm);
    }
  }, [user?.phone, data, setData]);

  const handleChange = (e) => {
    const updatedForm = { ...form, [e.target.name]: e.target.value };
    setForm(updatedForm);
    setData(updatedForm);
  };

  return (
    <div className="contactInfoForm">
      <div>
        <label className="checkoutLabel">Primary Phone Number *</label>
        <input
          className="checkoutInput"
          type="tel"
          name="primaryPhone"
          value={form.primaryPhone || ''}
          onChange={handleChange}
          required
          disabled
        />
      </div>
      <div>
        <label className="checkoutLabel">Secondary Phone Number (Optional)</label>
        <input
          className="checkoutInput"
          type="tel"
          name="secondaryPhone"
          value={form.secondaryPhone || ''}
          onChange={handleChange}
          placeholder="e.g., 0782498881"
        />
      </div>
    </div>
  );
};

export default ContactInfo;
