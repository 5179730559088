import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersByUserId } from '../actions/ordersActions';
import Wishlist from '../components/Wishlist';
import AccountLayout from '../components/AccountLayout';
import '../styles/myaccount.css';

const MyAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const orders = useSelector((state) => state.orders.orders);

    useEffect(() => {
        if (user) {
            dispatch(getOrdersByUserId());
        }
    }, [dispatch, user]);

    const handleTrackOrdersClick = () => {
        navigate('/orders');
    };

    const countOrdersByStatus = (status) => {
        return orders.filter(order => order.status === status).length;
    };
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#wishlist') {
          const element = document.getElementById('wishlist');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);

    return (
        <div className="myaccount-container">
            <AccountLayout />
            <div className="myaccount-main-content">
                <div className="myaccount-header">
                    <div className="myaccount-user-info">
                        <div className="myaccount-profile-circle">K-MART</div>
                        <div>
                            <p>Hi, {user ? user.name : 'Guest'}</p>
                            <p className="myaccount-savings">You have saved US$ 0 so far!</p>
                        </div>
                    </div>
                    <div className="myaccount-details">
                        <div>Coupons: <span>0</span></div>
                        <div>Reward Points: <span>0.00 P</span></div>
                    </div>
                </div>

                <div className="myaccount-track-orders">
                    <h2>Track Orders</h2>
                    <div className="myaccount-order-status">
                        <div>{countOrdersByStatus('pending')} <span>Pending</span></div>
                        <div>{countOrdersByStatus('preparing')} <span>Preparing Shipment</span></div>
                        <div>{countOrdersByStatus('in_transit')} <span>In Transit</span></div>
                        <div>{countOrdersByStatus('delivered')} <span>Delivered</span></div>
                        <div>{countOrdersByStatus('cancelled')} <span>Cancel/Refund</span></div>
                    </div>
                    <button onClick={handleTrackOrdersClick} className="myaccount-track-orders-button">
                        Go to Orders Page
                    </button>
                </div>
                <div id="wishlist" className="myaccount-wishlist">
                    <Wishlist />
                </div>
                <div className="myaccount-contact-section">
                    <h2>Contact Us</h2>
                    <p>No inquiries have been made.</p>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
