import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/ordersummary.css";

const OrderSummary = ({ cartTotal = 0, orders = [] }) => {
  const location = useLocation();
  const isCartPage = location.pathname === "/cart";

  // Calculate total from orders if they exist
  const ordersTotal = orders.length > 0 
    ? orders.reduce((sum, order) => sum + Number(order.total_price), 0)
    : cartTotal;

  return (
    <div className="order-summary">
      <h4>Order Summary</h4>
      <div className="summary-detail">
        <span>Subtotal:</span>
        <span>{ordersTotal.toFixed(2)} RWF</span>
      </div>
      <div className="summary-detail">
        <span>Tax:</span>
        <span>{(ordersTotal * 0.15).toFixed(2)} RWF</span>
      </div>
      <div className="summary-total">
        <strong>Total:</strong>
      <strong>{(ordersTotal * 1.15).toFixed(2)} RWF</strong>
      </div>
      {isCartPage && (
        <Link to="/checkout">
          <button className="checkout-button">Proceed to Checkout</button>
        </Link>
      )}
    </div>
  );
};

export default OrderSummary;
