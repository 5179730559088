import React, { useState, useEffect } from 'react';
import '../styles/checkout.css';

const ShippingInfo = ({ data, setData }) => {
  const [form, setForm] = useState(data || {});

  useEffect(() => {
    setForm(data);
  }, [data]);

  const handleChange = (e) => {
    const updatedForm = { ...form, [e.target.name]: e.target.value };
    setForm(updatedForm);
    setData(updatedForm);
  };

  return (
    <div className="shippingInfoForm">
      <div>
        <label className="checkoutLabel">District *</label>
        <input
          className="checkoutInput"
          type="text"
          name="district"
          value={form.district || ''}
          onChange={handleChange}
          required
          placeholder="e.g., Gasabo"
        />
      </div>
      <div>
        <label className="checkoutLabel">City *</label>
        <input
          className="checkoutInput"
          type="text"
          name="city"
          value={form.city || ''}
          onChange={handleChange}
          required
          placeholder="e.g., Kigali"
        />
      </div>
      <div>
        <label className="checkoutLabel">Country *</label>
        <input
          className="checkoutInput"
          type="text"
          name="country"
          value={form.country || ''}
          onChange={handleChange}
          required
          placeholder="e.g., Rwanda"
        />
      </div>
      <div>
        <label className="checkoutLabel">House Address *</label>
        <input
          className="checkoutInput"
          type="text"
          name="houseAddress"
          value={form.houseAddress || ''}
          onChange={handleChange}
          required
          placeholder="e.g., KG 29 Ave 16"
        />
      </div>
    </div>
  );
};

export default ShippingInfo;
