import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../styles/topbanner.css';

const TopBanner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const announcements = [
    { text: '🎁 Free Gift on Orders Above 50,000RWF' },
    { text: '✨ New Member 15% OFF - Join Now!' },
    { text: '🚚 Free Shipping Over 60,000RWF + Extra Benefits' }
  ];

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => 
      prev === announcements.length - 1 ? 0 : prev + 1
    );
  }, [announcements.length]);

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? announcements.length - 1 : prev - 1
    );
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 4000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  return (
    <div className="top-banner">
      <div className="banner-container">
        <button className="banner-nav prev" onClick={prevSlide}>
          <FaChevronLeft size={12} />
        </button>
        <div className="banner-content">
          <div 
            className="banner-slide" 
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {announcements.map((announcement, index) => (
              <div key={index} className="announcement">
                <span className="announcement-text">{announcement.text}</span>
              </div>
            ))}
          </div>
        </div>
        <button className="banner-nav next" onClick={nextSlide}>
          <FaChevronRight size={12} />
        </button>
      </div>
    </div>
  );
};

export default TopBanner; 