import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSite } from '../actions/siteActions';
import { useImage } from '../contexts/ImageContext';

const SiteHead = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.site);
  const { getImageUrl } = useImage();

  useEffect(() => {
    dispatch(fetchSite());
  }, [dispatch]);

  useEffect(() => {
    if (settings?.favicon) {
      let link = document.querySelector("link[rel='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = getImageUrl(settings.favicon);
    }
  }, [settings, getImageUrl]);

  return null;
};

export default SiteHead;
