const initialState = {
  loading: false,
  error: null,
  settings: {
    id: null,
    name: '',
    favicon: '',
    light_logo: '',
    dark_logo: '',
    email: '',
    phone: '',
    address: '',
    facebook: '',
    twitter: '',
    instagram: '',
    pinterest: '',
    youtube: '',
    tiktok: '',
    terms: '',
    privacy: '',
    legal: '',
    refund: '',
    faqs: [],
    created_at: '',
    updated_at: '',
    is_deleted: false
  }
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SITE_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_SITE_SUCCESS':
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case 'FETCH_SITE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default siteReducer; 