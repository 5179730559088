import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../actions/ProductActions.js';
import Loader from './Loader.jsx';
import ProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';
import '../styles/recommendations.css';

const Recommendations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, products, error } = useSelector((state) => state.products);
  const scrollRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="recommendations-section">
      <div className="recommendations-title-and-chevron">
        <h3 className="recommendations-title">Recommendations</h3>
        <div className="recommendations-chevrons">
          <button className="nav-arrow" onClick={scrollLeft}>←</button>
          <button className="nav-arrow" onClick={scrollRight}>→</button>
        </div>
      </div>
      <div className="recommendations-grid" ref={scrollRef}>
        {products.map((product) => (
          <div
            key={product.product_id}
            className="recommendations-product-item"
            onClick={() => handleProductClick(product.product_id)}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
