import React from 'react';
import ImageSlider from '../components/ImageSlider';
import BestSellers from '../components/BestSellers';
import FeaturedBrands from '../components/FeaturedBrands';
import Recommendations from '../components/Recommendations';
import ScrollToTop from '../components/ScrollToTop';
import '../styles/Home.css';
import AdSection from '../components/AdSection';
import Trending from '../components/Trending';
import ShopCategories from '../components/ShopCategories';
import TopBanner from '../components/TopBanner';

function Home() {
  return (
    <>
      <TopBanner />
      <div className="wrapper">
        <div className="content">
          <ImageSlider />
          <AdSection />
          <BestSellers className="scroll-home"/>
          <Recommendations className="scroll-home"/>
          <ShopCategories className="scroll-home"/>
          <Trending />
        </div>
        <div className="brands">
          <FeaturedBrands/>
        </div>
        <ScrollToTop />
      </div>
    </>
  );
}

export default Home;
