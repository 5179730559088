import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProducts } from '../actions/ProductActions';
import { selectProducts } from '../selectors/ProductSelector';
import ProductCard from './ProductCard';
import Loader from './Loader';
import '../styles/searchresults.css';

const SearchResults = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const products = useSelector(selectProducts);
  const loading = useSelector((state) => state.products.loading);
  const error = useSelector((state) => state.products.error);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const query = new URLSearchParams(location.search).get('q') || '';

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, products.length]);

  useEffect(() => {
    if (query) {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase()) ||
        product.category.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(results);
    } else {
      setFilteredProducts(products);
    }
  }, [products, query]);

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="search-results-container">
      <h2 className="search-results-title">
        {query ? `Search Results for "${query}"` : 'All Products'}
      </h2>
      <div className="products-grid">
        {filteredProducts.map((product) => (
          <div 
            key={product.product_id} 
            onClick={() => handleProductClick(product.product_id)}
            style={{ cursor: 'pointer' }}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
