import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../actions/authActions';
import AccountLayout from '../components/AccountLayout';
import '../styles/accountinfo.css';

const AccountInformation = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const [formData, setFormData] = useState({
        name: user ? user.name || '' : '',
        email: user ? user.email || '' : '',
        phone: user ? user.phone || '' : '',
        address: user ? user.address || '' : '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateUser(formData));
    };

    return (
        <div className="myaccount-container">
            <AccountLayout />
            <div className="account-info-container">
                <h2>Account Information</h2>
                <div className="account-sections">
                    <div className="account-section">
                        <h3>Personal Details</h3>
                        <div className="info-grid">
                            <div className="info-item">
                                <label>Full Name:</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="info-item">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="info-item">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="info-item">
                                <label>Address:</label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="account-section">
                        <h3>Account Details</h3>
                        <div className="info-grid">
                            <div className="info-item">
                                <label>Member Since:</label>
                                <span>March 20, 2023</span>
                            </div>
                            <div className="info-item">
                                <label>Account Status:</label>
                                <span className="status-badge verified">Verified</span>
                            </div>
                            <div className="info-item">
                                <label>Last Login:</label>
                                <span>Today at 12:45 PM</span>
                            </div>
                            <div className="info-item">
                                <label>Membership Type:</label>
                                <span>Premium Member</span>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className="updateinfo-button" onClick={handleSubmit}>
                    Update Information
                </button>
            </div>
        </div>
    );
};

export default AccountInformation;
