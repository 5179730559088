import React, { useState, useEffect } from 'react';
import { FaUser, FaHeadset, FaSearch, FaTimes, FaHeart, FaBars } from 'react-icons/fa';
import { FaCartShopping } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../variables';
import '../styles/Header.css';
import AutoComplete from './AutoComplete';
import { selectProducts } from '../selectors/ProductSelector';
const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const products = useSelector(selectProducts);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/products/category/parent`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const getSuggestions = (value) => {
    if (!value.trim()) {
      return [];
    }

    const inputValue = value.trim().toLowerCase();
    const productSuggestions = products
      .filter(product => product && product.name)
      .map(product => ({
        text: product.name,
        type: 'product',
        id: product.product_id
      }));
    const categorySuggestions = categories
      .filter(category => category && category.name)
      .map(category => ({
        text: category.name,
        type: 'category',
        id: category.category_id
      }));
    const allSuggestions = [...productSuggestions, ...categorySuggestions]
      .filter(item => item.text.toLowerCase().includes(inputValue))
      .slice(0, 5);

    return allSuggestions;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const newSuggestions = getSuggestions(value);
    console.log('Setting suggestions:', newSuggestions); 
    setSuggestions(newSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.type === 'category') {
      navigate(`/category/${suggestion.id}`);
    } else {
      navigate(`/search?q=${suggestion.text}`);
    }
    
    // Clear the search after navigation
    setSearchTerm('');
    setSuggestions([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm) {
      navigate(`/search?q=${trimmedSearchTerm}`);
      setSearchTerm('');
      setSuggestions([]);
    }
  };

  const handleCategoryClick = async (categoryId) => {
    try {
      await axios.get(`${API_URL}/api/products/category/${categoryId}/`);
      navigate(`/category/${categoryId}`);
    } catch (error) {
      console.error('Error fetching category products:', error);
    }
  };

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleCloseAutocomplete = () => {
    setSuggestions([]);
  };

  return (
    <header>
      <div className="header-wrapper">
        <div className="container">
          <Link to="/" className="logo-container">
            <div className="logo">
              <span className="logo-text black">K</span>
              <span className="logo-text green">-Mart</span>
            </div>
            <span className="korean-products">Purchase Korean cosmetic directly</span>
          </Link>

          <form className="search-bar" onSubmit={handleSearch}>
            <div className="search-input-wrapper">
              <input
                type="text"
                className="search-input"
                placeholder="Search for products or categories..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button type="submit" className="search-button">
                <FaSearch className="search-icon" />
              </button>
              {suggestions.length > 0 && (
                <AutoComplete 
                  suggestions={suggestions}
                  searchTerm={searchTerm}
                  onSuggestionClick={handleSuggestionClick}
                  onClose={handleCloseAutocomplete}
                />
              )}
            </div>
          </form>

          <div className="icons-container">
            {user && user.username ? (
              <Link to="/myaccount" className="icon-item">
                <FaUser className="icon" />
                <span>{user.username}</span>
              </Link>
            ) : (
              <Link to="/signin" className="icon-item">
                <FaUser className="icon" />
                <span>Sign in</span>
              </Link>
            )}
            <Link to="/cart" className="icon-item">
              <FaCartShopping className="icon" />
              <span>Cart</span>
            </Link>
            <Link to="/myaccount#wishlist" className="icon-item">
              <FaHeart className="icon" />
              <span>Wishlist</span>
            </Link>
            <Link to="/contactus" className="icon-item">
              <FaHeadset className="icon" />
              <span>Help</span>
            </Link>
          </div>

          <button className="menu-icon" onClick={toggleNavVisibility}>
            <FaBars />
          </button>
        </div>
      </div>
      <nav className={isNavVisible ? 'visible' : ''}>
        <button className="close-menu" onClick={toggleNavVisibility}>
          <FaTimes />
        </button>
        <div className="nav-container">
          <div className="nav-items ">
            <div className="nav-item dropdown">
              <button className="dropdown-button">Categories</button>
              <div className="dropdown-content">
                <div className="dropdown-inner">
                  <div className="categories-grid">
                    {categories.slice(0, Math.ceil(categories.length/2)).map((category) => (
                      <button
                        key={category.category_id}
                        className="category-item"
                        onClick={() => handleCategoryClick(category.category_id)}
                      >
                        {category.name}
                      </button>
                    ))}
                    {/* Second column */}
                    {categories.slice(Math.ceil(categories.length/2)).map((category) => (
                      <button
                        key={category.category_id}
                        className="category-item"
                        onClick={() => handleCategoryClick(category.category_id)}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Link to="/best"><button>Best </button></Link>
            <Link to="/new"><button>New </button></Link>
            <Link to="/productspage"><button>Sale</button></Link>
            {categories.slice(0, 6).map((category) => (
              <div key={category.category_id} className="nav-item dropdown">
                <button className="dropdown-button">{category.name}</button>
                <div className="dropdown-content">
                  <div className="dropdown-inner">
                    {Array.isArray(category.children) && category.children.length > 0 ? (
                      <div className="dropdown-subcategories">
                        {category.children.map((child) => (
                          <button
                            key={child.category_id}
                            className="subcategory"
                            onClick={() => handleCategoryClick(child.category_id)}
                          >
                            {child.name}
                          </button>
                        ))}
                      </div>
                    ) : (
                      <div className="no-categories">No subcategories available</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="nav-items">
            <button>Membership</button>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;