import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/cartmodal.css';

const CartModal = ({ isOpen, closeModal, cartItem }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;
  if (!cartItem) {
    return (
      <div className="cart-modal-overlay">
        <div className="cart-modal-content">
          <h2 className="cart-modal-title">Error</h2>
          <p>Item could not be added to the cart.</p>
          <button onClick={closeModal}>Close</button>
        </div>
      </div>
    );
  }

  return (
    <div className="cart-modal-overlay">
      <div className="cart-modal-content">
        <h2 className="cart-modal-title">Added to Your Cart</h2>
        <div className="cart-modal-body">
          <img
            src={cartItem.product_image}
            alt={cartItem.product_name}
            className="cart-modal-image"
          />
          <div className="cart-modal-details">
            <h3 className="cart-modal-product-name">{cartItem.product_name}</h3>
            <p className="cart-modal-quantity">Qty: {cartItem.quantity}</p>
            <p className="cart-modal-price"> {cartItem.price} RWF</p>
          </div>
        </div>
        <div className="cart-modal-actions">
          <button
            className="cart-modal-button view-bag"
            onClick={() => navigate('/cart')}
          >
            View Cart
          </button>
          <button
            className="cart-modal-button continue-shopping"
            onClick={closeModal}
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
