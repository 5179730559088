import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCT_BY_ID_REQUEST,
  FETCH_PRODUCT_BY_ID_SUCCESS,
  FETCH_PRODUCT_BY_ID_FAILURE,
  FETCH_PRODUCT_SIZES_REQUEST,
  FETCH_PRODUCT_SIZES_SUCCESS,
  FETCH_PRODUCT_SIZES_FAILURE,
  FETCH_PRODUCT_OPTIONS_REQUEST,
  FETCH_PRODUCT_OPTIONS_SUCCESS,
  FETCH_PRODUCT_OPTIONS_FAILURE,
} from "../actions/ProductActions.js";

const initialState = {
  loading: false,
  products: [],
  selectedProduct: null,
  sizes: [],
  options: [],
  error: "",
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch all products cases
    case FETCH_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: "" };
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.payload };
    case FETCH_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Fetch product by ID cases
    case FETCH_PRODUCT_BY_ID_REQUEST:
      return { ...state, loading: true, error: "" };
    case FETCH_PRODUCT_BY_ID_SUCCESS:
      return { ...state, loading: false, selectedProduct: action.payload };
    case FETCH_PRODUCT_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Fetch product sizes cases
    case FETCH_PRODUCT_SIZES_REQUEST:
      return { ...state, loading: true, error: "" };
    case FETCH_PRODUCT_SIZES_SUCCESS:
      return { ...state, loading: false, sizes: action.payload };
    case FETCH_PRODUCT_SIZES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Fetch product options cases
    case FETCH_PRODUCT_OPTIONS_REQUEST:
      return { ...state, loading: true, error: "" };
    case FETCH_PRODUCT_OPTIONS_SUCCESS:
      return { ...state, loading: false, options: action.payload };
    case FETCH_PRODUCT_OPTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default productReducer;
