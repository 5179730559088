import React, { useState } from 'react';
import { FaChevronDown, FaFacebookF, FaInstagram, FaXTwitter, FaTiktok, FaYoutube } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import logo from '../assets/images/songstarklogo.png';
import momologo from '../assets/images/momo icon.png';
import visa from '../assets/images/visa.png';
import mastercard from '../assets/images/mastercard.png';
import paypal from '../assets/images/paypal.png';
import dhl from '../assets/images/dhllogo.png';
import '../styles/Footer.css'; 

function Footer() {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className='footer-container'>
      <div className='footer'>
        <div className={`footer-section ${openSection === 'account' ? 'active' : ''}`}>
          <h3 onClick={() => toggleSection('account')}>
            MY ACCOUNT
            <FaChevronDown className="chevron-icon" />
          </h3>
          <div className="footer-content">
            <p><Link to="/orders">Track orders</Link></p> 
            <p><Link to="/myaccount">Wishlist</Link></p>
            <p><Link to="/contactus">Contact us</Link></p>
          </div>
        </div>

        <div className={`footer-section ${openSection === 'about' ? 'active' : ''}`}>
          <h3 onClick={() => toggleSection('about')}>
            ABOUT US
            <FaChevronDown className="chevron-icon" />
          </h3>
          <div className="footer-content">
            <p>K-Mart is a platform that sells Korean products in Rwanda</p>
            <p>We aim to build strong ties between Korea and Rwanda by creating good exchange channels, growing trust, and increasing economic cooperation.</p>
          </div>
        </div>

        <div className={`footer-section ${openSection === 'help' ? 'active' : ''}`}>
          <h3 onClick={() => toggleSection('help')}>
            HELP
            <FaChevronDown className="chevron-icon" />
          </h3>
          <div className="footer-content">
            <p>Customer service</p>
            <p>FAQS</p>
          </div>
        </div>

        <div className={`footer-section ${openSection === 'terms' ? 'active' : ''}`}>
          <h3 onClick={() => toggleSection('terms')}>
            TERMS & POLICIES
            <FaChevronDown className="chevron-icon" />
          </h3>
          <div className="footer-content">
            <p><Link to="/terms">Terms & Conditions</Link></p>
            <p>Legal notices</p>
            <p><Link to="/terms">Privacy policy</Link></p>
          </div>
        </div>
      </div>
      
      <div className="business-info">
        <div className="company-details">
          <img src={logo} alt="Song & Stark Cooperation Rwanda Ltd." />
          <h3>Song & Stark Cooperation Rwanda Ltd.</h3>
        </div>
        <div className="company-info">
          <p>CEO: WAN SONG</p>
          <p>Company Code: 121143548</p>
          <p>Address:KG 11 Ave, IKAZE HOUSE (Kisimenti),F5- 506 Kigali - RWANDA</p>
          <p>E-mail: info@songstark.com</p>
          <a href="https://rwanda.songstark.com/" target="_blank" rel="noreferrer" className="info-link">Click here for business information</a>
        </div>
        
        <div className="payment-delivery">
          <div className="social-media">
            <a href="https://facebook.com" target="_blank" rel="noreferrer"><FaFacebookF /></a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer"><FaInstagram /></a>
            <a href="https://x.com" target="_blank" rel="noreferrer"><FaXTwitter /></a>
            <a href="https://tiktok.com" target="_blank" rel="noreferrer"><FaTiktok /></a>
            <a href="https://youtube.com" target="_blank" rel="noreferrer"><FaYoutube /></a>
          </div>
          <h4>Payment Methods</h4>
          <img src={paypal} alt="Paypal" />
          <img src={momologo} alt="MOMO" />
          <img src={visa} alt="VISA" />
          <img src={mastercard} alt="Mastercard" />
          <div className="delivery-method">
            <h4>Delivery Methods</h4>
            <img src={dhl} alt="DHL" />
            {/* Add delivery methods */}
          </div>
        </div>
      </div>
      <p className="copyright">© 2023 Song & Stark Cooperation Rwanda Ltd. All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
