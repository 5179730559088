import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../actions/authActions';

const AccountSidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutUser(navigate));
    };

    const navigateToMyAccount = (section) => {
        navigate('/myaccount', { state: { section } });
    };

    return (
        <div className="myaccount-sidebar">
            <div className="myaccount-menu-section">
                <h3>My Shopping</h3>
                <ul>
                    <li onClick={() => navigate('/orders')} style={{ cursor: 'pointer' }}>Track Orders</li>
                    <li>Cancellation History</li>
                    <li onClick={() => navigate('/cart')} style={{ cursor: 'pointer' }}>Cart</li>
                    <li onClick={() => navigateToMyAccount('wishlist')} style={{ cursor: 'pointer' }}>Wish List</li>
                    <li>Payment Preference</li>
                    <li onClick={() => navigateToMyAccount('coupons')} style={{ cursor: 'pointer' }}>Coupons</li>
                    <li onClick={() => navigateToMyAccount('rewards')} style={{ cursor: 'pointer' }}>Reward Points</li>
                </ul>
            </div>
            <div className="myaccount-menu-section">
                <h3>My Profile</h3>
                <ul>
                    <li onClick={() => navigate('/accountinfo')} style={{ cursor: 'pointer' }}>Account Information</li>
                    <li>Beauty Profile</li>
                    <li>Addresses</li>
                    <li>Login History</li>
                    <li>Deactivate Account</li>
                </ul>
            </div>
            <button onClick={handleLogout} className="myaccount-track-orders-button">Logout</button>
        </div>
    );
};

export default AccountSidebar;
