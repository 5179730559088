import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import ProductCard from "../components/ProductCard";
import "../styles/new.css";

function ProductsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, products, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;
  if (!products || products.length === 0)
    return <div>No products available.</div>;

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="products-page new-products-container">
      <h1 className="new-products-title">All Products</h1>
      <div className="new-products-grid">
        {products.map((product) => (
          <div
            key={product.product_id}
            onClick={() => handleProductClick(product.product_id)}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductsPage;
