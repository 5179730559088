import React from 'react';
import { FaBox, FaFolder, FaTimes } from 'react-icons/fa';
import '../styles/AutoComplete.css';

const AutoComplete = ({ suggestions, searchTerm, onSuggestionClick, onClose }) => {
  if (!searchTerm || suggestions.length === 0) return null;

  return (
    <div className="autocomplete-container">
      <button className="close-button" onClick={onClose}>
        <FaTimes />
      </button>
      {suggestions.map((suggestion, index) => (
        <div 
          key={index} 
          className={`suggestion-item ${suggestion.type}`}
          onClick={() => onSuggestionClick(suggestion)}
        >
          {suggestion.type === 'category' ? (
            <FaFolder className="suggestion-icon" />
          ) : (
            <FaBox className="suggestion-icon" />
          )}
          <span>{suggestion.text}</span>
        </div>
      ))}
    </div>
  );
};

export default AutoComplete; 