import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../actions/ProductActions';
import ProductCard from './ProductCard';
import Loader from './Loader';
import { selectNewProducts } from '../selectors/ProductSelector';

const NewProducts = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.products);
  const newProducts = useSelector(selectNewProducts);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  if (newProducts.length === 0) {
    return (
      <div className="new-products-section">
        <h3 className="new-products-title">New Products</h3>
        <div className="no-new-products-message">
          No new products available at the moment.
        </div>
      </div>
    );
  }

  return (
    <div className="new-products-section">
      <h3 className="new-products-title">New Products</h3>
      <div className="products-grid">
        {newProducts.map((product) => (
          <ProductCard key={product.product_id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default NewProducts;
