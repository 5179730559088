import React, { useState } from 'react';
import { AiOutlineShoppingCart, AiOutlineHeart, AiFillHeart, AiOutlineStar, AiFillStar, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { addToWishlist, deleteFromWishlist } from '../actions/wishlistActions';
import { useImage } from '../contexts/ImageContext';
import '../styles/new.css';

const ProductCard = ({ product }) => {
  const {
    product_id,
    name,
    brand,
    base_price,
    discounted_price,
    product_rating,
    image,
    tags,
    oldPrice,
  } = product;
  const { getImageUrl } = useImage();
  const imageUrl = getImageUrl(image);
  const dispatch = useDispatch();

  const [isInWishlist, setIsInWishlist] = useState(false);
  const isHotDeal = tags.includes('HOT DEAL');

  const handleWishlistToggle = (e) => {
    e.stopPropagation();
    if (isInWishlist) {
      dispatch(deleteFromWishlist(product_id));
    } else {
      dispatch(addToWishlist(product_id));
    }
    setIsInWishlist(!isInWishlist);
  };

  return (
    <div className="new-product-card">
      <div
        className="new-product-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {tags.length > 0 && (
          <span className={`new-product-tag ${tags[0].toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
            {tags[0]}
          </span>
        )}
        <div className="new-product-icons-overlay">
          <button className={`icon-button ${tags[0].toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
            {isInWishlist ? (
              <AiFillHeart onClick={handleWishlistToggle} />
            ) : (
              <AiOutlineHeart onClick={handleWishlistToggle} />
            )}
          </button>
          <button className={`icon-button ${tags[0].toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
            <AiOutlinePlus />
          </button>
          <button className={`icon-button ${tags[0].toLowerCase() === 'hot deal' ? 'hot-deal' : ''}`}>
            <AiOutlineEye />
          </button>
        </div>
      </div>
      <div className="new-product-details">
        <div className="new-product-name-container">
          <h3 className="new-product-brand" dangerouslySetInnerHTML={{ __html: brand }}></h3>
          <AiOutlineShoppingCart className={`new-product-cart-icon ${isHotDeal ? 'hot-deal' : ''}`} />
        </div>
        <p className="new-product-name" dangerouslySetInnerHTML={{ __html: name}}></p>
        {oldPrice && <p className="new-product-old-price">{oldPrice} RWF</p>}
        <p className="new-product-old-price">{base_price} RWF</p>
        {base_price !== discounted_price && (
          <p className="new-product-price">
            {discounted_price} RWF
          </p>
        )}
        <div className="new-product-rating">
          {product_rating === 0 ? (
            <span className="new-product-rating"><AiOutlineStar className="outlined-star" /> No reviews yet </span>
          ) : (
            <span className="new-product-rating">
              {[...Array(5)].map((_, index) => (
                <span key={index}>
                  {index < Math.floor(product_rating) ? (
                    <AiFillStar className="filled-star" />
                  ) : (
                    <AiOutlineStar className="outlined-star" />
                  )}
                </span>
              ))}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
