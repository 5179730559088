import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../actions/ProductActions';
import ProductCard from './ProductCard';
import Loader from './Loader';
import { selectBestSellerProducts } from '../selectors/ProductSelector';

const BestProducts = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.products);
  const bestSellerProducts = useSelector(selectBestSellerProducts);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

    if (bestSellerProducts.length === 0) {
    return (
      <div className="new-products-section">
        <h3 className="new-products-title">Best Sellers</h3>
        <div className="no-new-products-message">
          No best sellers available at the moment.
        </div>
      </div>
    );
  }

  return (
    <div className="new-products-section">
      <h3 className="new-products-title">Best Sellers</h3>
      <div className="new-products-grid">
        {bestSellerProducts.map((product) => (
          <ProductCard key={product.product_id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default BestProducts;
