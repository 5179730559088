import axios from 'axios';
import { API_URL } from '../variables';

// Action Types
const WISHLIST_FETCH_REQUEST = 'WISHLIST_FETCH_REQUEST';
const WISHLIST_FETCH_SUCCESS = 'WISHLIST_FETCH_SUCCESS';
const WISHLIST_FETCH_FAIL = 'WISHLIST_FETCH_FAIL';

const WISHLIST_ADD_REQUEST = 'WISHLIST_ADD_REQUEST';
const WISHLIST_ADD_SUCCESS = 'WISHLIST_ADD_SUCCESS';
const WISHLIST_ADD_FAIL = 'WISHLIST_ADD_FAIL';

const WISHLIST_DELETE_REQUEST = 'WISHLIST_DELETE_REQUEST';
const WISHLIST_DELETE_SUCCESS = 'WISHLIST_DELETE_SUCCESS';
const WISHLIST_DELETE_FAIL = 'WISHLIST_DELETE_FAIL';

const WISHLIST_NOTIFICATION_CLEAR = 'WISHLIST_NOTIFICATION_CLEAR';

// Fetch Wishlist
export const fetchWishlist = (page = 1) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: token } };
  try {
    dispatch({ type: WISHLIST_FETCH_REQUEST });
    const { data } = await axios.post(`${API_URL}/api/products/wishlist`, { page, pagination: 10 }, config);
    console.log('API Response:', data);
    dispatch({ type: WISHLIST_FETCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: WISHLIST_FETCH_FAIL, payload: error.response?.data?.message || error.message });
  }
};

// Add Product to Wishlist
export const addToWishlist = (product_id) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: token } };
  try {
    dispatch({ type: WISHLIST_ADD_REQUEST });
    console.log('Adding to Wishlist with Product ID:', product_id);
    await axios.post(
      `${API_URL}/api/products/wishlist/add`,
      { product_id }, 
      config
    );
    dispatch({ 
      type: WISHLIST_ADD_SUCCESS,
      payload: {
        message: 'Item added to wishlist successfully',
        type: 'success'
      }
    });
    
    setTimeout(() => {
      dispatch({ type: WISHLIST_NOTIFICATION_CLEAR });
    }, 3000);
    
  } catch (error) {
    dispatch({ 
      type: WISHLIST_ADD_FAIL, 
      payload: {
        message: error.response?.data?.message || 'Failed to add item to wishlist',
        type: 'error'
      }
    });
    
    setTimeout(() => {
      dispatch({ type: WISHLIST_NOTIFICATION_CLEAR });
    }, 3000);
  }
};

// Delete Product from Wishlist
export const deleteFromWishlist = (wishlist_id) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: token } };
  try {
    dispatch({ type: WISHLIST_DELETE_REQUEST });
    await axios.post(`${API_URL}/api/products/wishlist/delete`, { wishlist_id }, config);
    dispatch({ type: WISHLIST_DELETE_SUCCESS, payload: wishlist_id });
  } catch (error) {
    dispatch({ type: WISHLIST_DELETE_FAIL, payload: error.response?.data?.message || error.message });
  }
};
