import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: false,
  error: null
};

// Try to get user data from localStorage
try {
  const userData = localStorage.getItem('user');
  const tokenData = localStorage.getItem('token');
  
  if (userData) {
    initialState.user = JSON.parse(userData);
  }
  if (tokenData) {
    initialState.token = tokenData;
    initialState.isAuthenticated = true;
  }
} catch (error) {
  // If there's an error parsing the data, clear localStorage
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  console.error('Error parsing auth data:', error);
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      localStorage.setItem('authToken', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
    },
    clearAuthData: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setAuthData, clearAuthData } = authSlice.actions;
export default authSlice.reducer;
