import {
    PLACE_ORDER_REQUEST,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAILURE,
    UPDATE_ORDER_STATUS_REQUEST,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE,
} from '../actions/ordersActions';

const initialState = {
    orders: [],
    orderStatus: null,
    loading: false,
    error: null,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLACE_ORDER_REQUEST:
            return { ...state, loading: true };
        case PLACE_ORDER_SUCCESS:
            return { ...state, loading: false, orderStatus: action.payload };
        case PLACE_ORDER_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case GET_ORDERS_REQUEST:
            return { ...state, loading: true };
        case GET_ORDERS_SUCCESS:
            console.log('Fetched orders payload:', action.payload);
            return { ...state, loading: false, orders: action.payload };
        case GET_ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case UPDATE_ORDER_STATUS_REQUEST:
            return { ...state, loading: true };
        case UPDATE_ORDER_STATUS_SUCCESS:
            const updatedOrders = state.orders.map(order =>
                order.id === action.payload.id ? action.payload : order
            );
            return { ...state, loading: false, orders: updatedOrders };
        case UPDATE_ORDER_STATUS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default orderReducer;
