// src/selectors/ProductSelector.js
import { createSelector } from 'reselect';

const getProductsState = (state) => state.products;
const getFilters = (state, filters) => filters;

export const selectProducts = createSelector(
  [getProductsState],
  (productsState) => productsState.products || []
);

export const selectFilteredProducts = createSelector(
  [selectProducts, getFilters],
  (products, filters) => {
    const { skinType, skinConcern, productBrand, productKeywords, productFormulation, priceRange } = filters;
    return products.filter((product) => {
      const matchesSkinType = skinType.length === 0 || (product.skinType && skinType.includes(product.skinType));
      const matchesSkinConcern = skinConcern.length === 0 || (product.skinConcern && skinConcern.includes(product.skinConcern));
      const matchesBrand = productBrand.length === 0 || productBrand.includes(product.brand);
      const matchesKeywords = productKeywords === "" || product.name.toLowerCase().includes(productKeywords.toLowerCase());
      const matchesFormulation = productFormulation.length === 0 || productFormulation.includes(product.formulation);
      const matchesPrice = parseFloat(product.base_price) >= priceRange[0] && parseFloat(product.base_price) <= priceRange[1];

      return matchesSkinType && matchesSkinConcern && matchesBrand && matchesKeywords && matchesFormulation && matchesPrice;
    });
  }
);

export const selectBestSellerProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && Array.isArray(product.tags) && 
        product.tags.some(tag => 
          tag.toLowerCase().includes('best') || 
          tag.toLowerCase().includes('bestseller') || 
          tag.toLowerCase().includes('best seller')
        );
    });
  }
);

export const selectNewProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && Array.isArray(product.tags) && 
        product.tags.some(tag => 
          tag.toLowerCase().includes('new')
        );
    });
  }
);

export const SelectTrendingProducts = createSelector(
  [selectProducts],
  (products) => {
    return products.filter((product) => {
      return product.tags && Array.isArray(product.tags) && 
        product.tags.some(tag => 
          tag.toLowerCase().includes('hot')
        );
    });
  }
);
