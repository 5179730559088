import axios from 'axios';
import { toast } from 'react-toastify';
import { setAuthData, clearAuthData } from '../reducers/authReducer';
import { API_URL } from '../variables';

export const loginUser = (formData, navigate) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/login`, formData);

    if (response.status === 200) {
      const { token, user } = response.data;
      localStorage.setItem('authToken', token);
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setAuthData({ user, token }));
      console.log('User after login:', user);
      toast.success('Login successful!');
      navigate('/');
    } else {
      toast.error(response.data.message || 'Login failed.');
    }
  } catch (error) {
    toast.error('Invalid username or password.');
  }
};

export const rehydrateAuth = () => (dispatch) => {
  const token = localStorage.getItem('authToken');
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  if (token && user) {
    dispatch(setAuthData({ token, user }));
  } else {
    dispatch(clearAuthData());
  }
};


export const logoutUser = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      toast.error('User is not authenticated.');
      return;
    }
    
    const response = await axios.get(`${API_URL}/api/users/logout`, {
      headers: {
        Authorization: token 
      }
    });
    
    if (response.status === 200) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      dispatch(clearAuthData());
      toast.success('Logged out successfully!');
      navigate('/signin');
    } else {
      toast.error(response.data.message || 'Logout failed.');
    }
  } catch (error) {
    toast.error('An error occurred during logout.');
  }
};

export const updateUser = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      toast.error('User is not authenticated.');
      return;
    }
    
    const response = await axios.post(`${API_URL}/api/users/updateMyAccount`, formData, {
      headers: {
        Authorization: token 
      }
    });
    
    if (response.status === 200) {
      const { user } = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setAuthData({ user, token }));
      toast.success('Account updated successfully!');
      navigate('/myaccount');
    } else {
      toast.error(response.data.message || 'Update failed.');
    }
  } catch (error) {
    toast.error('An error occurred during update.');
  }
};